export const purchaseUnitList: any = {
  Weight: [
    { label: "kg", value: "kg", name: "Kilogram" },
    { label: "lb", value: "lb", name: "Pound" },
    { label: "oz", value: "oz", name: "Ounce" },
  ],
  Volume: [
    { label: "l", value: "l", name: "Litters" },
    { label: "pt", value: "pt", name: "Pint" },
    { label: "gal", value: "gal", name: "Gallon" },
    { label: "fl oz", value: "fl oz", name: "Fluid Ounce" },
  ],
  Packaging: [
    { label: "pack", value: "pack", name: "Pack" },
    { label: "case", value: "case", name: "Case" },
    { label: "box", value: "box", name: "Box" },
    { label: "bundle", value: "bundle", name: "Bundle" },
    { label: "pallet", value: "pallet", name: "Pallet" },
  ],
  Custom: [
    {
      label: "Custom",
      value: "PurchaseUnitCustom",
    },
  ],
};

export const warningAndCriticalThreshold: any = {
  kg: {
    category: "weight",
    conversions: [
      {
        baseUnit: "kg",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
        name: "Kilogram",
      },
      {
        baseUnit: "g",
        multiplier: 1000,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Gram",
      },
      {
        baseUnit: "lb",
        multiplier: 2.20462,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Pound",
      },
      {
        baseUnit: "oz",
        multiplier: 35.274,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Ounce",
      },
      {
        baseUnit: "EA",
        type: "Custom",
        auto: true,
        separate: true,
        name: "Each",
      },

      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: true,
      },
    ],
  },
  lb: {
    category: "weight",
    conversions: [
      {
        baseUnit: "lb",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
        name: "Pound",
      },
      {
        baseUnit: "g",
        multiplier: 453.592,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Gram",
      },
      {
        baseUnit: "kg",
        multiplier: 0.453592,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Kilogram",
      },
      {
        baseUnit: "oz",
        multiplier: 16,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Ounce",
      },
      {
        baseUnit: "EA",
        type: "Custom",
        auto: true,
        separate: true,
        name: "Each",
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: true,
      },
    ],
  },
  oz: {
    category: "weight",
    conversions: [
      {
        baseUnit: "oz",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
        name: "Ounce",
      },
      {
        baseUnit: "g",
        multiplier: 28.3495,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Gram",
      },
      {
        baseUnit: "kg",
        multiplier: 0.0283495,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Kilogram",
      },
      {
        baseUnit: "lb",
        multiplier: 0.0625,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Pound",
      },
      {
        baseUnit: "EA",
        type: "Custom",
        auto: true,
        separate: true,
        name: "Each",
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: true,
      },
    ],
  },
  l: {
    category: "volume",
    conversions: [
      {
        baseUnit: "l",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
        name: "Litter",
      },
      {
        baseUnit: "ml",
        multiplier: 1000,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Milliliter",
      },
      {
        baseUnit: "pt",
        multiplier: 1.75975,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Pint",
      },
      {
        baseUnit: "gal",
        multiplier: 0.219969,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Gallon",
      },
      {
        baseUnit: "fl oz",
        multiplier: 35.1951,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Fluid Ounce",
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: false,
      },
    ],
  },
  pt: {
    category: "volume",
    conversions: [
      {
        baseUnit: "pt",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
        name: "Pint",
      },
      {
        baseUnit: "ml",
        multiplier: 568.261,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Milliliter",
      },
      {
        baseUnit: "l",
        multiplier: 0.568261,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Litter",
      },
      {
        baseUnit: "gal",
        multiplier: 0.125,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Gallon",
      },
      {
        baseUnit: "fl oz",
        multiplier: 20,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Fluid Ounce",
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: false,
      },
    ],
  },
  gal: {
    category: "volume",
    conversions: [
      {
        baseUnit: "gal",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
        name: "Gallon",
      },
      {
        baseUnit: "ml",
        multiplier: 4546.09,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Milliliter",
      },
      {
        baseUnit: "l",
        multiplier: 4.54609,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Litter",
      },
      {
        baseUnit: "pt",
        multiplier: 8,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Pint",
      },
      {
        baseUnit: "fl oz",
        multiplier: 160,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Fluid Ounce",
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: false,
      },
    ],
  },
  "fl oz": {
    category: "volume",
    conversions: [
      {
        baseUnit: "fl oz",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
        name: "Fluid Ounce",
      },
      {
        baseUnit: "ml",
        multiplier: 28.4131,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Milliliter",
      },
      {
        baseUnit: "l",
        multiplier: 0.0284131,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Litter",
      },
      {
        baseUnit: "pt",
        multiplier: 0.05,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Pint",
      },
      {
        baseUnit: "gal",
        multiplier: 0.00625,
        type: "fixed",
        auto: true,
        separate: false,
        name: "Gallon",
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: false,
      },
    ],
  },
  pack: {
    category: "packaging",
    conversions: [
      {
        baseUnit: "pack",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
      },
      {
        baseUnit: "units",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "pcs",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "bottles",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "cans",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "items",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "EA",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "box",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: true,
      },
    ],
  },
  case: {
    category: "packaging",
    conversions: [
      {
        baseUnit: "case",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
      },
      {
        baseUnit: "units",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "pcs",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "bottles",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "cans",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "items",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "EA",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "pack",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "box",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: true,
      },
    ],
  },
  box: {
    category: "packaging",
    conversions: [
      {
        baseUnit: "box",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
      },
      {
        baseUnit: "units",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "pcs",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "bottles",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "cans",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "items",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "EA",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "pack",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: true,
      },
    ],
  },
  bundle: {
    category: "packaging",
    conversions: [
      {
        baseUnit: "bundle",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
      },
      {
        baseUnit: "units",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "pcs",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "bottles",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "cans",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "items",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "EA",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "case",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "pack",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "box",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: true,
      },
    ],
  },
  pallet: {
    category: "packaging",
    conversions: [
      {
        baseUnit: "pallet",
        multiplier: 1,
        type: "fixed",
        auto: false,
        separate: false,
      },
      {
        baseUnit: "units",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "pcs",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "bottles",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "cans",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "items",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "EA",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "case",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "pack",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "box",
        type: "Custom",
        auto: true,
        separate: true,
      },
      {
        baseUnit: "Custom",
        type: "Custom",
        auto: true,
        separate: true,
      },
    ],
  },
  Custom: {},
};

export const paymentTypeSelectorList = [
  { id: "cashPayment", label: "Cash Payment" },
  { id: "cardPayment", label: "Card Payment" },
  { id: "otherPayment", label: "Other Payment" },
];

export const payment: any = {
  "2023/03/20": {
    Date: "2023/03/20",
    Orders: 6,
    SubTotal: "5.66",
    Discount: "8.66",
    Total: "1230.98",
    Cash: "3.21",
    Card: "8",
    OtherPayments: "10",
    Average: "34",
    Min: "9.12",
    Max: "7",
  },
  "2023/03/21": {
    Date: "2023/03/21",
    Orders: 7,
    SubTotal: "8.66",
    Discount: "6.66",
    Total: "15.98",
    Cash: "5.21",
    Card: "3",
    OtherPayments: "18",
    Average: "37",
    Min: "12.12",
    Max: "8",
  },
  "2023/03/23": {
    Date: "2023/03/23",
    Orders: 8,
    SubTotal: "8.66",
    Discount: "9.66",
    Total: "14.98",
    Cash: "7.21",
    Card: "12",
    OtherPayments: "15",
    Average: "36",
    Min: "14.12",
    Max: "9",
  },
  "2023/03/26": {
    Date: "2023/03/26",
    Orders: 4,
    SubTotal: "8.46",
    Discount: "9.69",
    Total: "14.94",
    Cash: "7.91",
    Card: "12.54",
    OtherPayments: "15.12",
    Average: "39",
    Min: "11.12",
    Max: "8",
  },
};

export const payInOutSelectorList = [
  { id: "pay_in", label: "Pay In" },
  { id: "pay_out", label: "Pay Out" },
];

export const typeSelectorList = [
  { id: "Return to Pay", label: "Return to Pay" },
  { id: "Cash In", label: "Cash In" },
  { id: "Delivery In", label: "Delivery In" },
  { id: "Oil", label: "Oil" },
  { id: "Payments", label: "Payments" },
  { id: "Supplier", label: "Supplier" },
  { id: "Cash Out", label: "Cash Out" },
  { id: "Online Payment", label: "Online Payment" },
  { id: "Safe Drop", label: "Safe Drop" },
  { id: "Other", label: "Other" },
];

export const partySizeSelectorList = [
  { id: "1", label: "Party of 1" },
  { id: "2", label: "Party of 2" },
  { id: "3", label: "Party of 3" },
  { id: "4", label: "Party of 4" },
  { id: "5", label: "Party of 5" },
  { id: "6", label: "Party of 6" },
  { id: "7", label: "Party of 7" },
  { id: "8", label: "Party of 8" },
  { id: "9", label: "Party of 9" },
  { id: "10", label: "Party of 10" },
  { id: "11", label: "Party of 11" },
  { id: "12", label: "Party of 12" },
  { id: "13", label: "Party of 13" },
  { id: "14", label: "Party of 14" },
  { id: "15", label: "Party of 15" },
];

export const defaultTypeSelectorList = [
  { id: "-4", label: "Dine In" },
  { id: "-3", label: "Delivery" },
  { id: "-2", label: "Walk In" },
  { id: "-1", label: "Collection" },
  { id: "0", label: "Future Table" },
];

export const vatPercentSelectorList = [
  { id: "0", label: "0" },
  { id: "5", label: "5" },
  { id: "12.5", label: "12.5" },
  { id: "20", label: "20" },
];

export const defaultModeSelectorList = [
  { id: "walk_in", label: "Walk In" },
  { id: "collection", label: "Collection" },
  { id: "delivery", label: "Delivery" },
  { id: "dine_in", label: "Dine In" },
  { id: "future_table", label: "Future Table" },
];

export const platformSelectorList = [
  { id: "walk in", label: "walk in" },
  { id: "phone", label: "phone" },
  { id: "kiosk", label: "kiosk" },
  { id: "online", label: "online" },
];

export const channelSelectorList = [
  { id: "eatpresto", label: "Eat Presto" },
  { id: "deliverect", label: "Deliverect" },
  { id: "justeat", label: "Just Eat" },
  { id: "deliveroo", label: "Deliveroo" },
  { id: "ubereats", label: "Uber Eats" },
];

export const initialModeColorList = [
  { id: "walk_in", color: "#18B5FA" },
  { id: "collection", color: "#895EC4" },
  { id: "delivery", color: "#CF5B6A" },
  { id: "dine_in", color: "#E5BB4F" },
  { id: "future_table", color: "#3A9D76" },
];

export const initialPlatformsColorList = [
  { id: "walk in", color: "#18B5FA" },
  { id: "phone", color: "#895EC4" },
  { id: "kiosk", color: "#CF5B6A" },
  { id: "online", color: "#E5BB4F" },
];

export const initialChannelColorList = [
  { id: "eatpresto", color: "#CF5B6A" },
  { id: "justeat", color: "#F08F3A" },
  { id: "deliveroo", color: "#00b3b3" },
  { id: "ubereats", color: "#3A9D76" },
];

export const OrderChannelDetailsObject: any = {
  eatpresto: { channelName: "Eat Presto", color: "#CF5B6A" },
  justeat: { channelName: "Justeat", color: "#F08F3A" },
  deliveroo: { channelName: "Deliveroo", color: "#00b3b3" },
  ubereats: { channelName: "Ubereats", color: "#3A9D76" },
  deliverect: { channelName: "Deliverect", color: "#33cc33" },
  wix: { channelName: "Wix", color: "#33cc33" },
  default: { color: "#7D7D7D" },
};

export const paymentTypesList: any = {
  cash: { paymentMethod: "Cash Payments" },
  card: { paymentMethod: "Card Payments" },
  voucher: { paymentMethod: "Voucher Payments" },
  online: { paymentMethod: "Online Payments" },
  uber: { paymentMethod: "Uber Payments" },
  deliveroo: { paymentMethod: "Deliveroo Payments" },
  just_eat: { paymentMethod: "Just Eat Payments" },
  "eatpresto-online-order-cash": {
    paymentMethod: "Eat Presto - Cash In-Store",
  },
  "eatpresto-online-order-card": {
    paymentMethod: "Eat Presto - Card In-Store",
  },
  "eatpresto-paid-online-order": { paymentMethod: "Eat Presto - Paid Online" },
  "deliverect-online-order-card": {
    paymentMethod: "Deliverect - Card In-Store",
  },
  "deliverect-online-order-cash": {
    paymentMethod: "Deliverect - Cash In-Store",
  },
  "deliverect-paid-online-order": { paymentMethod: "Deliverect - Paid Online" },
  "justeat-online-order-cash": { paymentMethod: "Just Eat - Cash In-Store" },
  "justeat-online-order-card": { paymentMethod: "Just Eat - Card In-Store" },
  "justeat-paid-online-order": { paymentMethod: "Just Eat - Paid Online" },
  "ubereats-online-order-cash": { paymentMethod: "Uber Eats - Cash In-Store" },
  "ubereats-online-order-card": { paymentMethod: "Uber Eats - Card In-Store" },
  "ubereats-paid-online-order": { paymentMethod: "Uber Eats - Paid Online" },
  "deliveroo-online-order-cash": { paymentMethod: "Deliveroo - Cash In-Store" },
  "deliveroo-online-order-card": { paymentMethod: "Deliveroo - Cash In-Store" },
  "deliveroo-paid-online-order": { paymentMethod: "Deliveroo - Paid Online" },
  "wix-online-order-cash": { paymentMethod: "Eat Presto - Cash In-Store" },
  "wix-online-order-card": { paymentMethod: "Eat Presto - Card In-Store" },
  "wix-paid-online-order": { paymentMethod: "Eat Presto - Paid Online" },
};

export const zeroToTwentyThreeList = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

export const dashboardFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: true },
  dueTimeOrderTimeSwitch: { available: false },
  dateRange: { available: true },
  mode: { available: true },
  orderStatus: { available: false },
  payInOut: { available: false },
  platform: { available: true },
  channel: { available: true },
  orderNo: { available: false },
  displayId: { available: false },
  receiptNo: { available: false },
  user: { available: false },
  paymentType: { available: false },
  type: { available: false },
  totalAmount: { available: false },
  discount: { available: false },
  partySize: { available: false },
  qty: { available: false },
  amount: { available: false },
  vatPercent: { available: false },
  orderTotal: { available: false },
  orderChannel: { available: false },
};

export const payInOutListFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: false },
  dueTimeOrderTimeSwitch: { available: false },
  dateRange: { available: true },
  mode: { available: false },
  orderStatus: { available: false },
  payInOut: { available: true },
  platform: { available: false },
  channel: { available: false },
  orderNo: { available: false },
  displayId: { available: false },
  receiptNo: { available: false },
  user: { available: false },
  paymentType: { available: false },
  type: { available: true },
  totalAmount: { available: true },
  discount: { available: false },
  partySize: { available: false },
  qty: { available: false },
  amount: { available: false },
  vatPercent: { available: false },
  orderTotal: { available: false },
  orderChannel: { available: false },
};

export const shiftListFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: false },
  dueTimeOrderTimeSwitch: { available: false },
  dateRange: { available: true },
  mode: { available: false },
  orderStatus: { available: false },
  payInOut: { available: false },
  platform: { available: false },
  channel: { available: false },
  orderNo: { available: false },
  displayId: { available: false },
  receiptNo: { available: false },
  user: { available: false },
  paymentType: { available: false },
  type: { available: false },
  totalAmount: { available: false },
  discount: { available: false },
  partySize: { available: false },
  qty: { available: false },
  amount: { available: false },
  vatPercent: { available: false },
  orderTotal: { available: false },
  orderChannel: { available: false },
};

export const paymentFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: true },
  dueTimeOrderTimeSwitch: { available: false },
  dateRange: { available: true },
  mode: { available: true },
  orderStatus: { available: false },
  payInOut: { available: false },
  platform: { available: true },
  channel: { available: true },
  orderNo: { available: false },
  displayId: { available: false },
  receiptNo: { available: false },
  user: { available: false },
  paymentType: { available: false },
  type: { available: false },
  totalAmount: { available: false },
  discount: { available: false },
  partySize: { available: false },
  qty: { available: false },
  amount: { available: false },
  vatPercent: { available: false },
  orderTotal: { available: false },
  orderChannel: { available: false },
};

export const stockMomentFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: false },
  dueTimeOrderTimeSwitch: { available: false },
  dateRange: { available: true },
  mode: { available: false },
  orderStatus: { available: false },
  payInOut: { available: false },
  platform: { available: false },
  channel: { available: false },
  orderNo: { available: false },
  displayId: { available: false },
  receiptNo: { available: false },
  user: { available: false },
  paymentType: { available: false },
  type: { available: false },
  totalAmount: { available: false },
  discount: { available: false },
  partySize: { available: false },
  qty: { available: false },
  amount: { available: false },
  vatPercent: { available: false },
  orderTotal: { available: false },
  orderChannel: { available: false },
};

export const saleListFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: false },
  dueTimeOrderTimeSwitch: { available: false },
  dateRange: { available: true },
  mode: { available: true },
  orderStatus: { available: false },
  payInOut: { available: false },
  platform: { available: true },
  channel: { available: true },
  orderNo: { available: true },
  displayId: { available: true },
  receiptNo: { available: true },
  user: { available: true },
  paymentType: { available: true },
  type: { available: false },
  totalAmount: { available: true },
  discount: { available: true },
  partySize: { available: true },
  qty: { available: false },
  amount: { available: false },
  vatPercent: { available: false },
  orderTotal: { available: false },
  orderChannel: { available: false },
};

export const stockOrderFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: false },
  dueTimeOrderTimeSwitch: { available: false },
  dateRange: { available: false },
  mode: { available: false },
  orderStatus: { available: false },
  payInOut: { available: false },
  platform: { available: false },
  channel: { available: false },
  orderNo: { available: false },
  displayId: { available: false },
  receiptNo: { available: false },
  user: { available: false },
  paymentType: { available: false },
  type: { available: false },
  totalAmount: { available: false },
  discount: { available: false },
  partySize: { available: false },
  qty: { available: false },
  amount: { available: false },
  vatPercent: { available: false },
  orderTotal: { available: false },
  orderChannel: { available: false },
};

export const salesItemsFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: true },
  dueTimeOrderTimeSwitch: { available: false },
  dateRange: { available: true },
  mode: { available: true },
  orderStatus: { available: false },
  payInOut: { available: false },
  platform: { available: true },
  channel: { available: true },
  orderNo: { available: false },
  displayId: { available: false },
  receiptNo: { available: false },
  user: { available: false },
  paymentType: { available: false },
  type: { available: false },
  totalAmount: { available: false },
  discount: { available: true },
  partySize: { available: false },
  qty: { available: true },
  amount: { available: true },
  vatPercent: { available: true },
  orderTotal: { available: false },
  orderChannel: { available: false },
};

export const voidTransactionFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: false },
  dueTimeOrderTimeSwitch: { available: false },
  dateRange: { available: true },
  mode: { available: false },
  orderStatus: { available: false },
  payInOut: { available: false },
  platform: { available: false },
  channel: { available: false },
  orderNo: { available: false },
  displayId: { available: false },
  receiptNo: { available: false },
  user: { available: true },
  paymentType: { available: false },
  type: { available: false },
  totalAmount: { available: true },
  discount: { available: false },
  partySize: { available: false },
  qty: { available: false },
  amount: { available: false },
  vatPercent: { available: false },
  orderTotal: { available: false },
  orderChannel: { available: false },
};

export const voidSummeryFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: true },
  dueTimeOrderTimeSwitch: { available: false },
  dateRange: { available: true },
  mode: { available: true },
  orderStatus: { available: false },
  payInOut: { available: false },
  platform: { available: true },
  channel: { available: true },
  orderNo: { available: false },
  displayId: { available: false },
  receiptNo: { available: false },
  user: { available: false },
  paymentType: { available: false },
  type: { available: false },
  totalAmount: { available: false },
  discount: { available: false },
  partySize: { available: false },
  qty: { available: false },
  amount: { available: false },
  vatPercent: { available: false },
  orderTotal: { available: false },
  orderChannel: { available: false },
};

export const orderFilterObject = {
  LocationSelector: { available: true },
  basedOnShift: { available: false },
  dueTimeOrderTimeSwitch: { available: true },
  dateRange: { available: true },
  orderNo: { available: true },
  orderStatus: { available: true },
  mode: { available: false },
  payInOut: { available: false },
  platform: { available: false },
  channel: { available: true },
  displayId: { available: false },
  receiptNo: { available: false },
  user: { available: false },
  paymentType: { available: true },
  type: { available: false },
  totalAmount: { available: false },
  discount: { available: false },
  partySize: { available: false },
  qty: { available: false },
  amount: { available: false },
  vatPercent: { available: false },
  orderTotal: { available: true },
  orderChannel: { available: true },
};

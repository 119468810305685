import * as React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";

import { CustomTheme } from "../../../../../types/customTheme";
import { warningAndCriticalThreshold } from "../../../../../utils/consts/list";
import TextfieldCommon from "../../../../../components/textField/TextfieldCommon";
import SwitchCommon from "../../../../../components/switches/SwitchCommon";
import TextFieldCustom from "../../../../../components/textField/TextdieldCustom";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: `1px solid ${theme.palette.background.entity_border}`,
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      marginRight: "8px",
      maxWidth: "200px",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    labelStyle: {
      fontSize: "17px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "15px",
      },
    },
    boxesStyleSelected: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      width: "100%",
      textTransform: "none",
      height: "100%",
      color: "white",
      cursor: "pointer",
      backgroundColor: theme.palette.custom.green.main,
      "&:hover": {
        backgroundColor: theme.palette.custom.green.main,
        color: "white",
      },
    },
    boxesStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      width: "100%",
      textTransform: "none",
      height: "100%",
      cursor: "pointer",
      backgroundColor:
        theme.palette.background.entity_highlight_background_current_footer,
      "&:hover": {
        backgroundColor: theme.palette.custom.green.main,
        color: "white",
      },
    },
  }),
);

export interface WizardModalProps {
  purchaseUnitObj: any;
  handleChangeSaleUnit: any;
  saleUnitObj: any;
  inputMultiplier: any;
  setInputMultiplier: any;
  baseUnit: any;
  setBaseUnit: any;
  purchaseUnit: any;
  handleChangeAutoConversion: any;
  isShowSeparate: any;
  isAutoConversion: any;
  handleChangeShowSeparate: any;
  isClickCustomBaseUnit: any;
}

/* Common modal UI.. */
const StepFour: React.FunctionComponent<WizardModalProps> = ({
  purchaseUnitObj,
  handleChangeSaleUnit,
  saleUnitObj,
  inputMultiplier,
  setInputMultiplier,
  baseUnit,
  setBaseUnit,
  purchaseUnit,
  handleChangeAutoConversion,
  isShowSeparate,
  isAutoConversion,
  handleChangeShowSeparate,
  isClickCustomBaseUnit,
}) => {
  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  return (
    <>
      {console.log(
        "sdfsdfsdfsd34sdfsdfsdf",
        warningAndCriticalThreshold,
        purchaseUnit,
        warningAndCriticalThreshold[purchaseUnit],
        warningAndCriticalThreshold[purchaseUnit].conversions,
        
        warningAndCriticalThreshold[purchaseUnit].conversions,
      )}
      <Typography variant="h6">
        Please choose the measurement unit in which you sell stock
      </Typography>
      <Typography variant="body1">
        This unit will be utilised to deduct stock when you process sales
        through the EPOS system
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            style={{ marginTop: "20px" }}
          >
            {warningAndCriticalThreshold[purchaseUnit].conversions.map(
              (data: any) => (
                <Box
                  gridColumn="span 2"
                  onClick={() => handleChangeSaleUnit(data)}
                  style={{
                    height: "80px",
                    width: "80px",
                    marginTop: "12px",
                  }}
                >
                  <Button
                    className={
                      saleUnitObj.baseUnit === data.baseUnit ||
                      (isClickCustomBaseUnit && data.baseUnit === "Custom")
                        ? classes.boxesStyleSelected
                        : classes.boxesStyle
                    }
                  >
                    <div>
                      <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {data.baseUnit}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "12px",
                        }}
                      >
                        {data.name}
                      </div>
                    </div>
                  </Button>
                </Box>
              ),
            )}
          </Box>
        </Grid>
        {/* <Grid item xs={12}> */}
        {/* <Autocomplete
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            size={"small"}
            id="locationSelectGlobal"
            color="inherit"
            options={transformedUnits}
            value={saleUnitObj}
            getOptionLabel={(option: any) => option.name || ""}
            style={{ width: 300 }}
            disableClearable
            onChange={handleChangeSaleUnit}
            classes={{ root: classes.autoComplete }}
            popupIcon={<ArrowDropDownIcon color={"inherit"} />}
            renderOption={(props: any) => {
              return (
                <Grid
                  container
                  {...props}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    zIndex: 0,
                    height: "20px",
                    marginBottom: "4px",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.titleColor}>
                      {props.name}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
            renderInput={(params: any) => (
              <TextField
                color="inherit"
                {...params}
                label=""
                placeholder="Select Sell Unit"
                variant="outlined"
              />
            )}
          />
        </Grid> */}
        {saleUnitObj && saleUnitObj.baseUnit === "Custom" && (
          <Grid item xs={12} style={{marginTop: "8px"}}>
            <TextFieldCustom
              id="baseUnit"
              name="baseUnit"
              type="text"
              label={
                <Typography className={classes.labelStyle}>
                  Selling Unit
                </Typography>
              }
              value={baseUnit}
              disabled={false}
              onChange={(e: any) => setBaseUnit(e.target.value?e.target.value:"")}
            />
          </Grid>
        )}
        {saleUnitObj &&
          Object.keys(saleUnitObj).length > 0 &&
          (!saleUnitObj.multiplier || saleUnitObj.baseUnit === "Custom") && (
            <Grid item xs={12} style={{marginTop: "8px"}}>
              <TextFieldCustom
                id="inputMultiplier"
                name="inputMultiplier"
                type="number"
                label={
                  <Typography className={classes.labelStyle}>
                    {`How many '${baseUnit ? baseUnit : "Selling Unit"}' in a '${
                      purchaseUnit ? purchaseUnit : "Purchase Unit"
                    }'`}
                  </Typography>
                }
                value={inputMultiplier}
                disabled={false}
                onChange={(e: any) => setInputMultiplier(e.target.value)}
              />
            </Grid>
          )}

        {saleUnitObj && saleUnitObj.baseUnit === "Custom" && (
          <Grid item xs={6} style={{marginTop: "8px"}}>
            <FormControlLabel
              style={{ marginLeft: "4px", marginRight: "0px" }}
              value="autoConversion"
              control={<SwitchCommon />}
              label={
                <Typography variant="body2" color="textSecondary">
                  Auto Conversion
                </Typography>
              }
              name="autoConversion"
              checked={isAutoConversion}
              labelPlacement="start"
              onChange={handleChangeAutoConversion}
            />
          </Grid>
        )}
        {saleUnitObj && saleUnitObj.baseUnit === "Custom" && (
          <Grid item xs={6} style={{marginTop: "8px"}}>
            <FormControlLabel
              style={{ marginLeft: "0px", marginRight: "0px" }}
              value="showSeparate"
              control={<SwitchCommon />}
              disabled={!isAutoConversion}
              label={
                <Typography variant="body2" color="textSecondary">
                  Show Separate
                </Typography>
              }
              name="showSeparate"
              checked={isShowSeparate}
              labelPlacement="start"
              onChange={handleChangeShowSeparate}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StepFour;
